import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import AuthContext from "../contexts/auth-context";
import { Link, useNavigate } from "react-router-dom";
import { useLocations } from "../hooks";
import moment from "moment";
import Button from "../components/atoms/Button";
import Dropdown from "../components/atoms/Dropdown";
import Icon from "../components/atoms/Icon";
import ButtonGroup from "../components/molecules/ButtonGroup";
import ProgressRing from "../components/atoms/ProgressRing";
import {
  Bar,
  BarChart,
  Cell,
  ComposedChart,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { formatTimestamp, generateDay, getQueuesAPI } from "../util";
import Spinner from "../components/atoms/Spinner";

export const HoveredItemContext = createContext();

export default function Parking() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const { locations } = useLocations();
  const [currentLocation, setCurrentLocation] = useState([]);
  const [time, setTime] = useState(0);
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [graphData, setGraphData] = useState([]);

  const onChangeTime = (e) => setTime(parseInt(e.target.value));

  const getData = () => {
    getQueuesAPI(
      "get-parking-daily-data",
      {
        locationId: currentLocation.id,
        date: moment(new Date()).subtract(1, "days").format("yyyy-MM-DD"),
      },
      (data) => setData(data)
    );
  };

  const processData = () => {
    let day = generateDay(moment(new Date()).subtract(1, "days").toDate());
    let processed = [];
    let processed2 = [];

    // for (let x = 0; x < day.length; x++) {
    // let currentMinute = moment(day[x]).toDate();
    let groups = Object.keys(data);

    for (let x = 0; x < groups.length; x++) {
      let groupId = groups[x];
      let currentGroup = data.filter((x_) => groupId in x_)[0];

      for (let y = 0; y < currentGroup[groupId].length; y++) {
        let currentData = currentGroup[groupId][y];

        processed.push({ groupId: groupId, ...currentData });
      }
      // let element = data[x];
      // processed.push({
      //   occupied: Math.round(
      //     (element.occupied / currentLocation.capacity) * 100
      //   ),
      //   timestamp: moment(new Date())
      //     .startOf("day")
      //     .add(element.timestamp - 1, "minutes")
      //     .toDate(),
      // });
    }

    processed.sort((j, k) => parseInt(j.groupId) - parseInt(k.groupId));

    for (let z = 0; z < groups.length; z++) {
      for (let y2 = 0; y2 < day.length; y2++) {
        let currentMinute = moment(day[y2]).toDate();
        let currentData = processed.filter(
          (a) => a.time === formatTimestamp(currentMinute)
        );

        let lotTotal = currentData.reduce((b, c) => b + c.occupancy, 0);
        let lotOccupancy = lotTotal / currentLocation.capacity;
        let avg = lotOccupancy * 100;

        // console.log(
        //   JSON.stringify({
        //     time: formatTimestamp(currentMinute),
        //     occupied: Math.round(lotTotal / currentLocation.capacity) * 100,
        //   })
        // );

        let r = {
          time: formatTimestamp(currentMinute),
          occupied: Math.round(avg),
          taken: lotTotal,
        };
        // console.log(JSON.stringify(r));

        processed2.push(r);
      }
    }

    setProcessedData(processed2);
  };

  function aggregate() {
    let chunks = [];
    for (let index = 0; index < processedData.length; index += 5) {
      let chunk = processedData.slice(index, index + 5);
      let avg = Math.round(chunk.reduce((x, y) => x + y.occupied, 0) / 5);
      chunks.push({
        timestamp: moment(chunk[0].time).toDate().getTime() / 1000,
        index: index,
        occupied: chunk[0].occupied,
      });
    }
    // console.log(JSON.stringify(chunks, null, 4));
    setGraphData(chunks);
    setLoading(false);
  }

  useEffect(() => {
    if (
      authCtx.loggedIn &&
      moment.unix(authCtx.tokenExpirationTime) < moment(new Date())
    ) {
      authCtx.logout();
      navigate("/auth");
    }
    setCurrentLocation(require("../floors/locations.json").locations[0]);
  }, []);

  useEffect(() => {
    if (currentLocation.length !== 0) {
      getData();
    }
  }, [currentLocation]);

  useEffect(() => {
    if (data.length !== 0) {
      processData();
    }
  }, [data]);

  useEffect(() => {
    if (processedData.length !== 0) {
      aggregate();
    }
  }, [processedData]);

  return (
    <>
      {authCtx.loggedIn &&
      moment.unix(authCtx.tokenExpirationTime) > moment(new Date()) ? (
        <>
          {currentLocation.length !== 0 && (
            <div className="row p-4">
              {/* <div style={{ whiteSpace: "pre-wrap" }}>
                {JSON.stringify(data)}
              </div> */}
              <div className="col-12 between mb-4 px-3">
                <ButtonGroup>
                  <Dropdown
                    classNameMenu="p-0"
                    className="btn-group"
                    target="parkingLots"
                    classNameBtn="btn"
                    icon="car-front-fill me-2"
                    text={currentLocation.name}>
                    {locations.map((x) => (
                      <Fragment key={x.id}>
                        {x.id !== currentLocation.id && (
                          <button
                            onClick={() =>
                              setCurrentLocation(
                                locations.filter((y) => y.id === x.id)[0]
                              )
                            }
                            className="dropdown-item border-bottom">
                            <div>{x.name}</div>
                            <div className="small fst-italic opacity-75">
                              {x.address}
                            </div>
                          </button>
                        )}
                      </Fragment>
                    ))}
                  </Dropdown>
                  <Button text={moment(new Date()).format("l")} />
                  <Button
                    text={moment(new Date())
                      .startOf("day")
                      .add(time, "minute")
                      .format("LT")}
                  />
                </ButtonGroup>
                <span className="form-text py-1">
                  <Icon name="geo-fill" className="me-2" />
                  {currentLocation.address}
                </span>
              </div>
              {loading ? (
                <div>
                  <Spinner />
                </div>
              ) : (
                <div className="col-12">
                  <div
                    className="border rounded p-3 between mx-auto shadow"
                    style={{ width: "100%", height: "650px" }}>
                    <div style={{ width: "30%" }}>
                      <div className="d-flex">
                        <ProgressRing
                          className="m-auto"
                          label="Total Occupancy"
                          progress={processedData[time]?.occupied}
                        />
                      </div>
                      <hr />
                      <div className="between text-center px-5">
                        <div>
                          <div className="h5">Total</div>
                          <div className="h1">{currentLocation.capacity}</div>
                        </div>
                        <div>
                          <div className="h5">Taken</div>
                          <div className="h1 text-danger">
                            {Math.round(
                              currentLocation.capacity *
                                (processedData[time].occupied / 100)
                            )}
                          </div>
                        </div>
                        <div>
                          <div className="h5">Available</div>
                          <div className="h1 text-success">
                            {currentLocation.capacity -
                              Math.round(
                                currentLocation.capacity *
                                  (processedData[time].occupied / 100)
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="my-3">
                        <div className="text-center mb-2">
                          <Icon name="clock" className="me-2" />
                          {moment(new Date())
                            .startOf("day")
                            .add(time, "minute")
                            .format("LT")}
                        </div>
                        <input
                          value={time}
                          min={0}
                          onChange={onChangeTime}
                          type="range"
                          className="form-range"
                          max={1439}
                          step={1}
                        />
                      </div>
                    </div>
                    <div className="m-auto" style={{ width: "70%" }}>
                      <div className="h3 text-center mb-4">
                        Total Occupancy %
                      </div>
                      {graphData.length !== 0 && (
                        <ResponsiveContainer
                          className=""
                          width="99%"
                          height={475}>
                          <ComposedChart data={graphData}>
                            <Tooltip
                              content={(props) => {
                                if (props.active) {
                                  return (
                                    <div className="bg-light p-3">
                                      <div>
                                        {props.payload?.[0].payload.occupied}%
                                        Occupied
                                      </div>
                                      <div>
                                        {moment(
                                          props.payload?.[0].payload.timestamp *
                                            1000
                                        ).format("LT")}
                                      </div>
                                    </div>
                                  );
                                }
                              }}
                            />
                            <XAxis
                              interval={11}
                              type="number"
                              scale="time"
                              style={{ fontSize: ".75rem" }}
                              domain={["auto", "auto"]}
                              tickFormatter={(x) =>
                                new Date(x * 1000).toLocaleTimeString("en-US", {
                                  hour: "numeric",
                                })
                              }
                              dataKey="timestamp">
                              <Label
                                value="Time (ET)"
                                position={"bottom"}
                                offset={-7}
                              />
                            </XAxis>
                            <YAxis
                              // domain={[0, 100]}
                              type="number"
                              dataKey="occupied"
                            />
                            <Line
                              stroke="#53b8aa"
                              type="monotoneX"
                              dataKey="occupied"
                              strokeWidth={3}
                            />
                            <Bar
                              fill="#53b8aa"
                              type="number"
                              dataKey="occupied">
                              {graphData.map((x) => (
                                <Cell
                                  // onClick={() => setTime(x.index)}
                                  stroke="black"
                                  strokeWidth={2}
                                  fill="black"
                                  // stroke={
                                  //   x.index === time
                                  //     ? x.occupied < 65
                                  //       ? "#53b8aa"
                                  //       : x.occupied < 85
                                  //       ? "darkorange"
                                  //       : "#953F40"
                                  //     : null
                                  // }
                                  // fill={
                                  //   x.index === time
                                  //     ? "white"
                                  //     : x.occupied < 65
                                  //     ? "#53b8aa"
                                  //     : x.occupied < 85
                                  //     ? "darkorange"
                                  //     : "#953F40"
                                  // }
                                />
                              ))}
                            </Bar>
                          </ComposedChart>
                        </ResponsiveContainer>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <div className="p-4">
          {authCtx.loggedIn &&
          moment.unix(authCtx.tokenExpirationTime) < moment(new Date()) ? (
            <>
              <Link to={"/auth"}>
                Session Token expired or invalid. Please log out, then log back
                in for a new session token
              </Link>
            </>
          ) : (
            <>
              <Link to={"/auth"}>
                Not Authorized. Please Log-In with given credentials or contact
                support.
              </Link>
            </>
          )}
        </div>
      )}
    </>
  );
}
