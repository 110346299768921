import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./App.css";
import Nav from "./components/molecules/Nav";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./contexts/auth-context";
import Auth from "./pages/Auth";
import ForgotPassword from "./pages/ForgotPassword";
import Parking from "./pages/Parking";

function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Nav />}>
            <Route path="/auth" element={<Auth />}></Route>
            <Route
              path="/auth/forgotPassword"
              element={<ForgotPassword />}></Route>
            <Route path="/parking" element={<Parking />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
