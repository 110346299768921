import Icon from "./Icon";

export default function ProgressRing({ className, size = 400, progress }) {
  return (
    <div className={className}>
      <svg width={size} height={size}>
        <circle
          cx={size / 2}
          cy={size / 2}
          fill="transparent"
          r={size / 2 - 40}
          stroke={
            progress < 65 ? "#53b8aa" : progress < 85 ? "darkorange" : "#953F40"
          }
          strokeWidth={40}
          strokeDasharray="1006"
          strokeDashoffset={1005 * (1 - progress / 100)}
        />
        <foreignObject
          className=""
          x={size / 2 - 95}
          y={size / 2 - 95}
          width={size / 2 - 5}
          height={size / 2 - 10}>
          <div className="d-flex h-100">
            <div className="m-auto text-center">
              <Icon className="h1" name="car-front" />
              <h1 style={{ fontSize: "5.2rem" }}>{progress}%</h1>
              <h3 style={{ fontSize: "2rem" }}>Full</h3>
            </div>
          </div>
        </foreignObject>
      </svg>
    </div>
  );
}
